import { io } from 'socket.io-client';
import { API_SOCKET } from './configs/constants';

var socket = null;

const initSocket = (auth) => {
  if (socket) return socket;
  socket = io(API_SOCKET, {
    query: {
      userId: auth?.user?.userId,
      userName: auth?.user?.user_name
    },
    reconnection: true,
    autoConnect: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity
  });

  socket.on('connect', () => {
    console.log('Connect with socket');
  });

  socket.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected after ${attemptNumber} attempts`);
  });

  socket.on('reconnect_error', (error) => {
    console.error('Reconnection error:', error);
  });
  
  return socket;
};
const getSocket = () => {
  return socket;
};

const disconnect = () => {
  socket.disconnect();
};

export { getSocket, disconnect, initSocket };
