import { createSlice } from '@reduxjs/toolkit';

// Dummy data simulating API response
const initialState = {
  orders:[]
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload.orders;
    },

   
  }
});

export const { completeOrder, completeItem, setOrders, markItems } =
  ordersSlice.actions;
export default ordersSlice.reducer;
