import { Fragment, useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DrawerDirection from '../../../utils/DrawerDirection';
import Calculator from './calculator/Calculator';
import style from './style/howMuchPaidDrawer.module.scss';
import { Spin } from 'antd';

export default function HowMuchPaidDrawer({
  open,
  setOpen,
  placeOrder,
  totalPrice,
  orderPlacementLoading
}) {
  const { t } = useTranslation();
  const [returnPrice, setReturnPrice] = useState(0);

  return (
    <DrawerDirection
      title={<p className="wh-clr">{t('enterHowMuchCustomerPaid')}</p>}
      width="50%"
      open={open}
      onClose={() => setOpen(false)}
      closeIcon={
        <CloseCircleFilled
          style={{
            color: '#7D8FA9',
            fontSize: 25,
            float: 'var(--right)'
          }}
        />
      }
    >
      <Calculator
        lastPriceUpdate={(price) => {
          const lastPrice = price - totalPrice;
          setReturnPrice(lastPrice);
        }}
      />

      {returnPrice ? (
        <div className={style['return']}>
          <br />
          <p>{t('return_to_customer')}</p>
          <div>
            <p>{returnPrice?.toLocaleString()}</p>
            <span>IQD</span>
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {orderPlacementLoading ? (
          <Spin />
        ) : (
          <button className={style['confirm']} onClick={() => placeOrder()}>
            {t('confirm')}
          </button>
        )}
      </div>
    </DrawerDirection>
  );
}
