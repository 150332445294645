import { configureStore } from '@reduxjs/toolkit';
import { cartsSlice } from './carts';
import { cartSocketSlice } from './cartFromSocket';
import ordersReducer from './ordersSlice';

const store = configureStore({
  reducer: {
    cartsSlice: cartsSlice.reducer,
    cartSocketSlice: cartSocketSlice.reducer,
    orders: ordersReducer,

  }
});

export default store;
