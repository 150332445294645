//! DEV
const API_URL = process.env.REACT_APP_API_URL;
export const API_SOCKET = process.env.REACT_APP_API_SOCKET;


export const localStorageName = 'pos-lezzoo-auth';
export const languageStorageName = 'pos-lezzoo-lang';

export const dateTimeFormat12 = 'YYYY-MM-DD hh:mm:ss A';
export const dateTimeFormat24 = 'YYYY-MM-DD hh:mm:ss';

export const orderTypeColors = {
  pending: '#FFCBD5',
  in_the_kitchen: '#B7E0F8',
  preparing: '#FFF4BB',
  prepared: 'var(--light-green-color)',
  serving: 'var(--light-green-color)'
};

export const daysOfWeek = [
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday'
];

export const colors = [
  '#CFDDDB',
  '#E4CCED',
  '#C2DBE9',
  '#CACAEE',
  '#C2E9DD',
  '#F0C8CF',
  '#E6DADE',
  '#F9C2D9',
  '#A9C2A9',
  '#CFDDDB',
  '#E4CCED',
  '#C2DBE9',
  '#CACAEE',
  '#C2E9DD',
  '#F0C8CF',
  '#E6DADE',
  '#F9C2D9',
  '#A9C2A9'
];

export const languages = [
  {
    id: 1,
    lang: 'en',
    label: 'English'
  },
  {
    id: 2,
    lang: 'ar',
    label: 'Arabic'
  },
  {
    id: 3,
    lang: 'kr',
    label: 'Kurdish'
  },
  {
    id: 4,
    lang: 'bd',
    label: 'Sorani'
  }
];

export default API_URL;
