import style from '../../assets/styles/pages/homeVersions/store.module.scss';
import { Fragment, useEffect, useReducer } from 'react';
import MainPart from './store/MainPart';
import CheckoutPart from './store/CheckoutPart';
import CartStore from './store/CartStore';
import { discountCalculate } from '../../function/discountCalculate';
import OrderLezzooDrawer from './store/OrderLezzooDrawer';
import { useAxiosGet } from '../../configs/axios';
import { useTranslation } from 'react-i18next';
import { localStorageName } from '../../configs/constants';
import useLocaleStorage from '../../configs/localStorage';
import { getSocket } from '../../socket';
import statusChangeAudio from '../../audio/status_change.mp3';
import cartChangeAudio from '../../audio/cart_change.mp3';
import { Badge, Row } from 'antd';
import alertGif from '../../assets/images/alert.gif';

export default function Store({ isGrocery }) {
  const { t } = useTranslation();
  const [auth] = useLocaleStorage(localStorageName);

  const socket = getSocket();

  const initialState = {
    discountType: '',
    discountValue: 0,
    customerInfo: {},
    isSetCustomerDiscount: false,
    partnerId: undefined,
    paymentType: 'cash',
    items: {},
    showLezzooOrderDrawer: false,
    singleOrderLezzoo: {},
    note: ''
  };
  const [states, dispatch] = useReducer(reducer, initialState);

  const { data: dataOrderLezzoo, request: getLezzooOrders } = useAxiosGet(
    '/orders/lezzooOrders',
    {
      autoRun: true
    }
  );

  const { request: requestVendorList } = useAxiosGet(
    '/vendors/get_user_vendors',
    {
      autoRun: true
    }
  );

  useEffect(() => {
    const statusAudio = new Audio(statusChangeAudio);
    requestVendorList().then((res) => {
      res.forEach((vendor) => {
        socket.emit('join', `vendor:${vendor.vendor_id}:orderStatusChannel`);
      });
    });

    const orderChangeListener = (data, callback) => {
      getLezzooOrders();
      statusAudio.currentTime = 0;
      statusAudio.play();
      if (callback) {
        const res = 'hi';
        callback(res);
      }
    };

    socket.on('orderStatusChanged', orderChangeListener);
  }, []);

  return (
    <Fragment>
      <section className={style['store-view']}>
        <section
          className={`${style['store-view-container']}
        ${
          Object.keys(states.items).length ? style['active-product-part'] : ''
        }`}
        >
          {dataOrderLezzoo?.length ? (
            <Row align={'middle'} className={style['title-container']}>
              <Badge count={dataOrderLezzoo[0]?.counter} showZero color="#FF555C">
                <img
                  style={{
                    height: 40
                  }}
                  src={alertGif}
                />
              </Badge>

              <h3 className={style['title']}>{t('Lezzoo Orders')}</h3>
            </Row>
          ) : null}
          {dataOrderLezzoo?.length ? (
            <section className={style['lezzoo-order']}>
              {dataOrderLezzoo &&
                dataOrderLezzoo[0]?.orders?.map((order) => {
                  return (
                    <div
                      key={order?.order_id}
                      onClick={() => [
                        dispatch({
                          type: 'SET_STATE',
                          changeStates: {
                            showLezzooOrderDrawer: true,
                            singleOrderLezzoo: order
                          }
                        })
                      ]}
                    >
                      <p>#{order?.order_id}</p>
                      <span>
                        {t('total')}: {order?.order_total?.toLocaleString()}{' '}
                        {auth?.user?.default_currency_code}
                      </span>
                    </div>
                  );
                })}
            </section>
          ) : null}

          <section
            className={style['product-part']}
            style={{
              '--height-product-part': dataOrderLezzoo?.orders ? '90%' : '100%'
            }}
          >
            <MainPart
              dispatch={dispatch}
              states={states}
              isGrocery={isGrocery}
            />
            {Object.keys(states.items).length ? (
              <CheckoutPart dispatch={dispatch} states={states} />
            ) : null}
          </section>
        </section>

        {Object.keys(states.items).length ? (
          <section className={style['cart-part']}>
            <CartStore
              dispatch={dispatch}
              states={states}
              isGrocery={isGrocery}
            />
          </section>
        ) : null}

        {states.showLezzooOrderDrawer ? (
          <OrderLezzooDrawer
            open={states.showLezzooOrderDrawer}
            singleOrderLezzoo={states.singleOrderLezzoo}
            dispatch={dispatch}
            getLezzooOrders={getLezzooOrders}
          />
        ) : null}
      </section>
    </Fragment>
  );
}

/**
 * For product I added total price
 */
function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        ...action.changeStates
      };
    case 'ADD_CART':
      addCart(state, action);
      return {
        ...state
      };
    case 'INCREASE_QTY':
      increaseQty(state, action);
      return {
        ...state
      };
    case 'DECREASE_QTY':
      decreaseQty(state, action);
      return {
        ...state
      };
    case 'SET_DISCOUNT_PER_PRODUCT':
      setDiscountPerProduct(state, action);
      return {
        ...state
      };
    case 'SET_GIFT_PER_PRODUCT':
      setGiftPerProduct(state, action);
      return {
        ...state
      };
    case 'SET_NOTE':
      setNote(state, action);
      return {
        ...state
      };
    default:
      return state;
  }
}

function calculateTotalPrice(quantity, price) {
  return quantity * price;
}

function addCart(state, action) {
  const { product_id } = action?.product;

  const singleItem = state.items[product_id];
  if (product_id in state.items) {
    // Add quantity by 1 if the product ID is contain
    singleItem.quantity = (singleItem.quantity || 0) + 1;
    singleItem.totalSingleProductPrice = calculateTotalPrice(
      singleItem.quantity,
      singleItem.price
    );
  } else {
    // Set quantity to 1 if the product ID is not contain
    const sort = Object.keys(state.items || {}).length + 1; // put sort for each product when added for sorting in cartStore.jsx.
    state.items[product_id] = {
      ...action.product,
      quantity: 1,
      totalSingleProductPrice: action.product.price,
      sort: sort
    };
  }
}

function increaseQty(state, action) {
  const { product_id, numberGift } = action?.product;
  const singleItem = state.items[product_id];
  const qty = (singleItem.quantity || 0) + 1;
  singleItem.quantity = qty;
  const checkGiftQty = qty - (numberGift || 0);
  singleItem.totalSingleProductPrice = calculateTotalPrice(
    checkGiftQty,
    singleItem.price
  );
}

function decreaseQty(state, action) {
  const { quantity, product_id, numberGift } = action?.product;
  const singleItem = state.items[product_id];

  if (quantity == 1) {
    delete state.items[product_id];
  } else {
    const qty = (quantity || 0) - 1;
    singleItem.quantity = qty;
    if (qty < numberGift) {
      singleItem.numberGift = qty;
    }

    const checkGiftQty = qty - (singleItem?.numberGift || 0);

    singleItem.totalSingleProductPrice = calculateTotalPrice(
      checkGiftQty,
      singleItem.price
    );
  }
}

function setDiscountPerProduct(state, action) {
  const { discountValue, product, discountType } = action;
  const { product_id, totalSingleProductPrice } = product;

  const singleItem = state.items[product_id];
  const discountSinglePrice = discountCalculate(
    totalSingleProductPrice,
    discountType,
    discountValue
  );

  singleItem.discount_amount = discountSinglePrice;
  singleItem.discount_type = discountType;
  singleItem.discount_value = discountValue;
}

function setGiftPerProduct(state, action) {
  const { product, numberGift } = action;
  const { product_id, quantity } = product;
  const singleItem = state.items[product_id];
  const checkGiftQty = quantity - (numberGift || 0);
  singleItem.numberGift = numberGift;
  singleItem.totalSingleProductPrice = calculateTotalPrice(
    checkGiftQty,
    singleItem.price
  );
}

function setNote(state, action) {
  const { note } = action;
  state.note = note;
}
